<template>
  <v-container fluid>
    <div v-if="lengthOfAllCandidates >= 1">
      <candidates-list></candidates-list>
    </div>
    <div v-else>
      <no-candidates></no-candidates>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CandidatesList from "../components/CandidatesList.vue";
import NoCandidates from "../components/NoCandidates/NoCandidates.vue";

export default {
  components: { CandidatesList, NoCandidates },
  name: "listView",
  data: () => ({}),
  computed: {
    ...mapGetters(["lengthOfAllCandidates"]),
  },
};
</script>
