<template>
  <v-container fluid>
    <candidate-tabs></candidate-tabs>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="dataForListView.headers"
      :items="dataForListView.content"
      :search="search"
      :item-class="rowClass"
    >
      <template v-slot:item.rating="{ item }">
        <rating :candidateId="item.id" :candidateRating="item.rating"></rating>
      </template>
      <template v-slot:item.status="{ item }">
        <v-select
          class="status--change--list"
          :items="statuses"
          item-text="value"
          :label="
            capitalize(
              item.status.toLowerCase() === 'undecided'
                ? 'new'
                : item.status.toLowerCase()
            )
          "
          @input="changeStatusSelect"
          @focus="setIdForCandidate(item.id)"
          hide-details
          single-line
        ></v-select>
      </template>
      <template v-slot:item.actions="{ item }">
        <card-actions :notified="item.notStatusBool" :id="item.id" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CandidateTabs from "./CandidatesStatusTabs.vue";
import { mapGetters, mapActions } from "vuex";
import Rating from "./Rating.vue";
import CardActions from "./CardActions.vue";

export default {
  components: { CandidateTabs, Rating, CardActions },
  name: "CandidateList",
  computed: {
    ...mapGetters(["dataForListView"]),
  },
  data() {
    return {
      search: "",
      candidateId: "",
      statuses: [
        { value: "New" },
        { value: "Accepted" },
        { value: "Declined" },
        { value: "Interviewed" },
      ],
    };
  },
  methods: {
    ...mapActions(["changeCandidateStatus"]),
    setIdForCandidate(id) {
      this.candidateId = id;
    },
    changeStatusSelect(value) {
      const val = () => {
        if (value == "New") return "Undecided";
        return value[0].toLowerCase() + value.substr(1).toLowerCase();
      };
      this.changeCandidateStatus({
        id: this.candidateId,
        filter: val(),
      });
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    rowClass(item) {
      if (item.notStatusBool) {
        const rowClass = "sentStatusBackground";
        return rowClass;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status--change--list {
  width: 120px;
  margin-top: 0;
  padding-top: 0;
}
.v-card__title {
  background-color: #fff !important;
}
.vue-star-rating {
  justify-content: start;
}
.v-data-table,
.v-card__title {
  border-radius: 10px;
}
</style>
